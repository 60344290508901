import React from 'react'
import { styled } from 'styled-components'

import img1 from '../assets/team-images/member1.png'
import img2 from '../assets/team-images/member2.png'
import img3 from '../assets/team-images/member3.png'
import img4 from '../assets/team-images/member4.png'
import img5 from '../assets/team-images/member5.png'
import img6 from '../assets/team-images/member6.png'

import NextButton from '../components/utilities/NextButton'
// import img6 from '../assets/hero-bcg.jpg'

// #83d5d4
// #ffda63
// #ff7f5a
// Other colors:
// #9be590
// #ff7f5a
// #89b4e7
// #ff7f5a
// #ff5c59
const teamMembers = [
	{
		name: 'Jhakendra Sharma',
		designation: 'director & CEO of Bhargo Capital',
		image: img1,
		background: 'LightSteelBlue',
		portfolio: `Mr. Jhakendra Sharma, an accomplished entrepreneur and investor with a diverse background spanning various industries. Mr. Sharma holds a distinguished position as the Director of Bhargo Capital in Melbourne, Australia. His achievements include a certification in decentralized finance (DeFi) and noteworthy investments in a range of startups. His collaborative approach and visionary thinking make him an integral part of our team, contributing to our mission of supporting purpose-driven entrepreneurs and fostering transformative solutions. `,
	},
	{
		name: 'Dr. Baburam Adhikari',
		designation: 'Research Analyst and M&A Expert',
		image: img6,
		background: '#9be590',
		portfolio: `Dr. Baburam Adhikari is a seasoned research analyst specializing in Mergers and Acquisitions (M&A). He holds a Doctor of Philosophy (Ph.D.) from the University of Southern Queensland, Australia, along with a Master of Business Administration (MBA) and a Master of Professional Accounting (MPA) from Australia. His research on the impact of M&A in the banking sector has been published in several academic journals. With extensive expertise in finance and accounting, Dr. Adhikari plays a pivotal role in driving our company’s growth and expanding both our product portfolio and capital base.`,
	},
	{
		name: 'Manoj Kumar',
		designation: 'Senior Business Development Manager',
		image: img5,
		background: '#ff7f5a',
		portfolio: `Manoj Kumar, a senior International Business Development professional with over 30 years of experience, specializes in engineering, business consulting, and project management, particularly in environmental monitoring and clean energy. For the past 19 years, he has led global and domestic markets, delivering strategic solutions and managing project execution. He is a Member of The Institute of Engineers Australia and holds a Master of Business Management from Australia and a Bachelor of Engineering in Electronics from India.`,
	},
	{
		name: 'Jullian Jayasinghe',
		designation: 'Strategic Marketing & Management Liaison',
		image: img4,
		background: '#83d5d4',
		portfolio: `Mr. Jullian Jayasinghe is a seasoned professional in Strategic Marketing & Management, boasting a wealth of experience across diverse industries. With past collaborations involving major brands like Ford, Holden, Mitsubishi, Hyundai, National Mutual, AXA, and others, his contributions to the team are rich with insights. Over the last 27 years, he’s worn multiple hats as an entrepreneur, investor, and expert in sales, marketing, management, and investments. His involvement in Angel investing, coupled with a deep understanding of emerging markets, makes him a valuable asset to our team at Bhargo Capital.`,
	},
	{
		name: 'Krishna Prasad Sharma',
		designation: 'Chief Operating Officer (South Asia and Middle East)',
		image: img3,
		background: '#ffb85d',
		portfolio: `Mr. Krishna Prasad Sharma is an adept Public Relations Manager with a wealth of experience that enriches our team at Bhargo Capital. With a Master's Degree and a proven track record in influential roles such as Assistant Governor of Rotary International Nepal-Bhutan, Joint-Secretary of the Private and Boarding Schools’ Organization Nepal in Lumbini Province, Immediate Past President of Rotary Club of Tilottama, Rupandehi, and Charter President of Lions Club of Sainamaina-Malmala, Mr. Sharma brings a wealth of strategic insights to our team. With a global perspective cultivated through visits to countries like Australia, China, Indonesia, and more, Mr. Sharma's expertise enhances our mission to foster transformative connections and strategic communication at Bhargo Capital.`,
	},
	{
		name: 'Poshkant Sharma',
		designation: 'Business Analyst of Bhargo Capital',
		image: img2,
		background: 'MediumPurple',
		portfolio: `Mr. Poshkant Sharma is a master in Computer Science and has 3.5 years of experience as a Business Intelligence Analyst, totaling 5 years of ICT experience. Skilled in various BI tools, including QlikView, MySQL Server, VB Script, and Advanced Excel, he possesses strong analytical and organizational skills. Poshkant's professional journey includes successful contributions as an IT Intern, Analyst, and Senior Research and Data Analyst. He is known for implementing innovative solutions. At Bhargo Capital, Mr. Sharma's expertise embodies our commitment to innovation and strategic insights.`,
	},
]

const TeamPage = () => {
	return (
		<>
			<Wrapper>
				<div className="section section-center page">
					<h4 className="text-title">our team</h4>

					<div className="team-members-container">
						{teamMembers.map((member, index) => {
							const { name, designation, image, background, portfolio } = member
							return (
								<div key={index} className="single-member">
									<div>
										<div
											key={index}
											className="member-image-container"
											style={{ background: background }}>
											<img src={image} alt={name} />
										</div>
									</div>
									<div>
										<div className="info">
											<div className="member-name">{name} </div>
											<p className="member-designation">{designation} </p>
										</div>

										<div className="portfolio-desc">{portfolio}</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</Wrapper>
			<NextButton url="/portfolio" nextPageTitle="portfolio" />
		</>
	)
}

const Wrapper = styled.div`
	.text-title {
		text-align: center;
		text-decoration: underline;
	}

	.single-member {
		display: grid;
		margin-bottom: 1rem;
		justify-items: center;
		.member-image-container {
			width: 10rem;
			height: 10rem;
			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.member-name {
			font-weight: 500;
		}

		.info {
			text-align: center;
			text-transform: capitalize;
		}
	}

	.portfolio-desc {
		text-align: justify;
	}

	@media (min-width: 786px) {
		.single-member {
			grid-template-columns: auto 1fr;
			column-gap: 3rem;

			.member-image-container {
				width: 17rem;
				height: 18rem;
			}

			.info {
				text-align: left;
			}
		}
	}
`

export default TeamPage
